<template>
  <div>
    <h2>
      {{ $t('reportError.successInfo.title') }}
    </h2>
    <v-row class="fill-height text-center justify-center">
      <v-col sm="12">
        <v-btn
          color="primary"
          elevation="6"
          x-large
          @click="goToQuickStart()"
        >
          {{ $t('reportError.successInfo.finish') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SuccessInfo',
  methods: {
    goToQuickStart () {
      this.$router.push({ name: 'QuickStartMachine' })
    }
  }
}
</script>
